import Vue from "vue";
import Vuex from "vuex";
import {ApiService} from "../services/TerminalApi"
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    headerText: "",
    countdownSeconds: 0,
    isLoaderVisible: false,
    result: 0,
    currentRound: 0,
    currentRoundDate: null,
    nextRound: 0,
    multiplier: 1,
    betsClosed: true,
    startGame: false,
    stopGame: true,
    roundSpan: 0,
    roundText: "",
    isDrawVisible: false,
    showJackpot: false,
    showMaintenance: false,
    showDisconnect: false,
    historyResultsRounds: [],
    currentLength: 0,
    connectedToHub: false,
    shopId: "",
    settings: [],
    jackpotRound: "",
    jackpotValue: "",
    jackpotShop: "",
    jackpotCode: "",
    globalJackpot: 0,
    localJackpot: 0,
  },
  mutations: {
    updateHeaderText(state, newText) {
      state.headerText = newText;
    },
    updateCountdown(state, seconds) {
      state.countdownSeconds = seconds;
    },
    changeCountdownState(state, isVisible) {
      state.isLoaderVisible = isVisible;
    },
    updateCurrentDrawResult(state, newResult) {
      state.result = newResult
    },
    updateNextRoundNo(state, newRoundNo) {
      state.nextRound = newRoundNo;
    },
    updateRoundNo(state, newRoundNo) {
      state.currentRound = newRoundNo;
    },
    updateRoundDate(state, newRoundDate) {
      state.currentRoundDate = newRoundDate;
    },
    updateBetsClosed(state, status) {
      state.betsClosed = status
    },
    updateRoundSpan(state, rs) {
      state.roundSpan = rs
    },
    updateRoundText(state, roundtext) {
      state.roundText = roundtext;
    },
    toggleDraw(state, isVisible) {
      state.isDrawVisible = isVisible;
    },
    updateHistoryResults(state, resultsRounds) {
      state.historyResultsRounds = resultsRounds;
    },
    resetDrawTable(state) {
      state.stopGame = false;
      state.startGame = false;
      state.isDrawVisible = false;
      state.result = 0;
      state.multiplier = 1;
    },
    stopGameStatus(state, value) {
      state.stopGame = value
    },
    startGameStatus(state, value) {
      state.startGame = value
    },
    setShopId(state, newShopId) {
      state.shopId = newShopId;
    },
    toggleHubConnection(state, newState){
      state.connectedToHub = newState
    },
    toggleJackpot(state, toggle) {
      state.showJackpot = toggle
    },
    toggleMaintenance(state, toggle) {
      state.showMaintenance = toggle
    },
    toggleDisconnect(state, toggle) {
      state.showDisconnect = toggle
    },
    updateMultiplier(state, value) {
      state.multiplier = value
    },
    setSettings(state, value) {
      state.settings = value
    },
    updateJackpotAmount(state, newAmount) {
      state.globalJackpot = newAmount;
    },
    updateLocalJackpotAmount(state, newAmount) {
      state.localJackpot = newAmount;
    },
    setJackpotInfo(state, jInfo) {
      state.jackpotRound = jInfo.round
      state.jackpotValue = jInfo.value
      state.jackpotShop = jInfo.shop
      state.jackpotCode = jInfo.jackpotCode
    }
  },
  actions: {
    getSettings({commit}) {
      let gameApi = new ApiService();
      gameApi.getSettings().then((response) => {
        commit("setSettings", response.data);
      })
    },
    incrementMultiplier({commit, dispatch, state}) {
      if (state.isDrawVisible == true && state.startGame == true && state.stopGame == false) {
        var timeout = 5000
        var current_max_duration = 5
        setTimeout(() => {
          for (let i=1; i<state.settings.length;i++) {
            if (state.multiplier >= state.settings[i][0] && state.multiplier < state.settings[i][1] )
            var random = Math.random() * (state.settings[i][3] - state.settings[i][2]) + state.settings[i][2]
            timeout = state.settings[i][4] - current_max_duration > 5 ? 5000 + (state.settings[i][4] - current_max_duration - 5) * 1000 : 5000
            current_max_duration = state.settings[i][4]
          }
          commit("updateMultiplier", state.multiplier + random)
          if (state.multiplier.toFixed(2) >= state.result) {
            commit("updateMultiplier", state.result)
            commit('startGameStatus', false)
            commit('stopGameStatus', true)
            setTimeout(() => {
              //commit('startGameStatus', false)
              //commit('stopGameStatus', true)
              commit('toggleDraw', false)
              commit('resetDrawTable')
            }, timeout)
          }
          dispatch("incrementMultiplier")
        }, 100)
      }
    },
    updateHeaderText(context, text) {
      context.commit("updateHeaderText", text);
    },
    updateCountdown(context, seconds) {
      context.commit("updateCountdown", seconds);
    },
    changeCountdownState(context, isVisible) {
      context.commit("changeCountdownState", isVisible);
    },
    resetDrawTable(context) {
      context.commit("resetDrawTable");
    },
    updateNextRoundNo(context, newRoundNo) {
      context.commit("updateNextRoundNo", newRoundNo);
    },
    updateRoundNo(context, newRoundNo) {
      context.commit("updateRoundNo", newRoundNo);
    },
    updateRoundText(context, newRoundText) {
      context.commit("updateRoundText", newRoundText);
    },
    toggleDraw(context, isVisible) {
      context.commit("toggleDraw", isVisible);
    },
    updateHistoryResults(context, resultsRounds) {
      context.commit("updateHistoryResults", resultsRounds);
    },
    setShopId(context, newShopId) {
      context.commit("setShopId", newShopId)
    },
    toggleHubConnection(context, newState){
      context.commit("toggleHubConnection", newState)
    },
    toggleJackpot(context, toggle) {
      context.commit("toggleJackpot", toggle)
    },
    toggleMaintenance(context, toggle) {
      context.commit("toggleMaintenance", toggle)
    },
    toggleDisconnect(context, toggle) {
      context.commit("toggleDisconnect", toggle)
    },
    updateJackpotAmount(context, newAmount) {
      context.commit("updateJackpotAmount", newAmount);
    },
    updateLocalJackpotAmount(context, newAmount) {
      context.commit("updateLocalJackpotAmount", newAmount);
    },
    setJackpotInfo(context, jInfo) {
      context.commit("setJackpotInfo", jInfo)
    }
  },
  getters: {
    result: state => {
      return state.result
    },
    betsClosed: state => {
      return state.betsClosed
    },
    currentRound: state => {
      return state.currentRound
    },
    countdownSeconds: state => {
      return state.countdownSeconds
    },
    isDrawVisible: state => {
      return state.isDrawVisible
    },
    startGame: state => {
      return state.startGame
    },
    stopGame: state => {
      return state.stopGame
    },
    multiplier: state => {
      return state.multiplier
    },
    currentRoundDate: state => {
      return state.currentRoundDate
    },
    historyResultsRounds: state => {
      return state.historyResultsRounds
    },
    localJackpot: state => {
      return state.localJackpot
    },
    globalJackpot: state => {
      return state.globalJackpot
    }
  }
});
