import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";
import settings from "../settings/generalSettings.js";
import { store } from "../store/index.js";
//import Vue from "vue";

export default () => {
  let rocketHub = new HubConnectionBuilder()
    .withUrl(settings[process.env.NODE_ENV].rocketServer + "/virtualsweb/RocketHub?shopId="+store.state.shopId)
    .configureLogging(LogLevel.Information)
    .build();
  var countdownTick;

  let startedPromise = null
  function startHub () {
    startedPromise = rocketHub.start().catch(() => {
      store.commit("toggleDisconnect", true);
      //console.log('Failed to connect with hub', err)
      return new Promise((resolve, reject) => 
        setTimeout(() => startHub().then(resolve).catch(reject), 5000))
    })
    return startedPromise
  }
  rocketHub.onclose(() => startHub())

  rocketHub.on("roundClosed", roundClosedMessage => {
    let result = roundClosedMessage["altitude"]
    startDraw(
      result,
      roundClosedMessage["remainingRoundSeconds"],
      roundClosedMessage["newRoundNo"],
    );
  });
  rocketHub.on("catchUp", catchUpInfo => {
    store.commit("toggleDisconnect", false);
    store.commit("toggleMaintenance", false);
    store.dispatch("toggleJackpot", false);
    showResults(
        catchUpInfo["actualRoundNo"],
        catchUpInfo["remainingSeconds"],
        catchUpInfo["roundHistory"],
        catchUpInfo["betsClosed"]
      );
    store.dispatch("updateJackpotAmount", 0);
    store.dispatch("updateLocalJackpotAmount", 0);
    store.commit("toggleHubConnection", true);
  });
  rocketHub.on("remainingRoundTime", data => {
    updateCountdown(data["remainingRoundSeconds"]);
  });
  rocketHub.on("prepareDraw", data => {
    updateCountdown(data["remainingRoundSeconds"]);
    store.commit("resetDrawTable");
    store.commit("toggleDraw", true);
    store.commit("updateHeaderText", "PARIURI ÎNCHISE!");
  });
  rocketHub.on("historyResults", roundHistoryInfo => {
    setTimeout(() => {
      showResults(
        roundHistoryInfo["rounds"][0]["roundNo"] + 1,
        roundHistoryInfo["remainingRoundSeconds"],
        roundHistoryInfo["rounds"],
        false
      );
    }, 1000);

  });
  rocketHub.on("jackpotInfo", data => {
    store.dispatch("updateJackpotAmount", data["globalOngoingJackpotAmount"].toFixed(2));
    store.dispatch("updateLocalJackpotAmount", data["localOngoingJackpotAmount"].toFixed(2));
    if (data["globalAwardedJackpot"] !== null && data["globalAwardedJackpot"]["advertiseTtl"].toFixed(2) > 0) {
    store.dispatch("setJackpotInfo", { "round": data["globalAwardedJackpot"]["roundNo"] , "value": data["globalAwardedJackpot"]["amount"].toFixed(2) , "jackpotCode": data["globalAwardedJackpot"]["jackpotCode"], "shop": data["globalAwardedJackpot"]["shopCode"] + ' ' + data["globalAwardedJackpot"]["shopName"] });
    store.dispatch("toggleJackpot", true);
    hideJackpot();
    }

  });
  rocketHub.on("maintenance", data => {
    if (data == true) {
      store.commit("toggleDisconnect", false);
      store.commit("toggleMaintenance", true);
    }
    if (data == false) {
      store.commit("toggleMaintenance", false);
    }
  })
  rocketHub.on("jackpotCatchUp", ( localJackpotInfo, globalJackpotInfo) => {
    store.dispatch("updateJackpotAmount", globalJackpotInfo["ongoingJackpotAmount"].toFixed(2));
    store.dispatch("updateLocalJackpotAmount", localJackpotInfo["ongoingJackpotAmount"].toFixed(2));
  });

  function showResults(nextRound, remainingSeconds, roundsHistory, betsClosed) {
    store.commit("updateRoundNo", nextRound);
    //store.commit("updateRoundDate", nextRoundDate);
    store.commit("updateBetsClosed", betsClosed)
    var historyRounds = [];
    updateCountdown(remainingSeconds);
    if (roundsHistory !== null) {
      for (let i = 0; i < roundsHistory.length; i++) {
        var historyDetails = {
          result: roundsHistory[i]["altitude"].toFixed(2),
          roundNo: roundsHistory[i]["roundNo"],
          roundTime: roundsHistory[i]["roundTime"]
        };
        historyRounds[i] = historyDetails;
      }
    }
    store.commit("updateHistoryResults", historyRounds);
    store.commit("updateRoundText", "");
    store.commit("updateHeaderText", "URMEAZĂ RUNDA " + nextRound);
    store.commit("toggleDraw", false);
  }
  function startDraw(
    result,
    remainingSeconds,
    nextRound
  ) {
    store.commit("updateCurrentDrawResult", result);
    setTimeout(() => {
      store.commit("startGameStatus", true);
      store.commit("stopGameStatus", false);
      store.dispatch("incrementMultiplier");
    }, 1);
    updateCountdown(remainingSeconds);
    store.commit("updateRoundText", "RUNDA ");
    store.commit("updateHeaderText", "EXTRAGERE ÎN CURS");
    //store.commit("updateNextRoundNo", nextRound);
    store.commit("updateRoundNo", nextRound - 1);
  }

  function updateCountdown(countdownSeconds) {
    store.commit("updateCountdown", countdownSeconds);
    if (!store.state.isLoaderVisible) {
      store.commit("changeCountdownState", true);
      startCountdown();
    }
  }

  function startCountdown() {
    if (store.state.countdownSeconds > 0) {
      store.commit("updateCountdown", store.state.countdownSeconds - 1);
      countdownTick = setTimeout(() => {
        startCountdown();
      }, 1000);
    } else {
      clearTimeout(countdownTick);
      store.commit("changeCountdownState", false);
    }
  }
  function hideJackpot() {
    setTimeout(() => {
      store.dispatch("toggleJackpot", false);
    }, 8000);
  }

  return rocketHub;
};
